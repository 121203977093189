import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "guides"
    }}>{`Guides`}</h1>
    <p>{`The point of sales registry keeps track of all points of sale, both manned (e.g. a vendor on board a train) and unmanned (e.g a ticket vending machine), using our cash register system. The registry will be populated by the operators through Entur Partner. It is not possible to do a sale from a point of sale not added to this registry.`}</p>
    <p>{`Important concepts:`}</p>
    <ul>
      <li parentName="ul">{`Point of sale (POS): The place where a transaction is completed. For mobile terminals (MT) this is the employee using the terminal rather than a physical location.`}</li>
      <li parentName="ul">{`Location: The location of a point of sale identified by coordinates and an optional NSR station identifyer in case the point of sale is situated at a station (whichever type of station, bus station, train station etc).`}</li>
    </ul>
    <p><img alt="ER Model" src={require("./ER-model.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      